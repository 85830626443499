<template>
  <div class="camera-index">
    <div class="logout" @click="logout">退出</div>
    <div class="camera-index-text">
      为确保顺利完成认证，<span class="camera-index-text-b">请对照照片示例确认所拍摄照片中脸部居中、清晰</span>，确认符合要求后点击【开始认证】。
    </div>
    <div class="photo-show">
      <van-image width="200px" height="200px" :src="photoShow" />
    </div>
    <div class="photo-show-text">图片示例</div>
    <div class="circle">
      <div v-if="isMobile">
        <van-uploader
          ref="uploader"
          v-model="fileList"
          multiple
          :max-count="1"
          :after-read="afterRead"
          :before-read="beforeRead"
          :preview-full-image="false"
          :disabled="checkStatus == 1"
          :deletable="true"
          :before-delete="deleteImg"
          capture="user"
          accept="image/*"
        >
          <van-image width="200px" height="200px" :src="uploadImg" @click="chooseFile" />
        </van-uploader>
      </div>
    </div>

    <div class="bottom-button" v-if="checkStatus != 1 && isMobile">
      <van-button :disabled="!isChecked" type="info" color="#563279" @click="onFaceMatch"
        >开始认证</van-button>
    </div>
    <div class="bottom-button" v-if="checkStatus != 1 && !isMobile">
      <van-button :disabled="!isChecked" type="info" color="#563279">暂不支持</van-button>
    </div>
    <!-- <div class="circle"> -->

    <!-- <div v-if="isMobile">
                <van-uploader
                        ref="uploader"
                        v-model="fileList"
                        multiple
                        :max-count="1"
                        :after-read="afterRead"
                        :before-read="beforeRead"
                        :preview-full-image="false"
                        :disabled="checkStatus==1"
                        capture="camera"
                        accept="image/*"
                />
            </div> -->
    <!-- <div v-else>
                <div class="pc-img">
                    <canvas ref="canvas" id="canvas"></canvas>
                </div>
                <div class="pc-video">
                    <video ref="video" autoplay></video>
                </div>
            </div>

            <van-circle
                    v-model="currentRate"
                    layer-color="#D6D6D6"
                    :color="gradientColor"
                    :rate="targetRate"
                    :speed="speed"
                    stroke-linecap="butt"
                    stroke-width="102"
                    size="196px"
            />
            <div class="item-canvas">
                <canvas id="procanvas1" class="procanvas"></canvas>
            </div> -->

    <!-- </div> -->

    <!-- <div class="camera-index-btn" v-if="checkStatus!=1&&isMobile">
            <van-button :disabled="isChecked" plain type="info" @click="chooseFile">立即认证</van-button>
        </div>
        <div class="camera-index-btn" v-if="checkStatus!=1&&!isMobile">
            <van-button :disabled="isChecked" plain type="info" @click="photoGraph">立即认证</van-button>
        </div>
        <div class="result_box" v-if="checkStatus==1">
            <van-icon class="my-icon" name="success"/>
            <div>识别已通过</div>
        </div>
        <div class="result_box" v-if="checkStatus==2">
            <van-icon class="my-icon" name="cross"/>
            <div>识别未通过</div>
        </div> -->
  </div>
</template>

<script>
import { cameraMixin } from "mixins/index/camera";

export default {
  name: "camera",
  mixins: [cameraMixin],
};
</script>

<style scoped lang="scss">
.camera-index {
  position: relative;
  padding-top: 60px;
  font-family: "PingFang SC";

  .logout {
    position: absolute;
    right: 20px;
    top: 30px;
    margin: 0;
    color: #8b2d87;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
  }
  .bottom-button {
    position: relative;
    left: 0px;
    // top:20px;
    bottom: 20px;
    width: 100%;
    height: 50px;
    z-index: 999;
    .van-button {
      color: #eee;
      height: 50px;
      margin: 0px 5% 0px 5%;
      width: 90%;
      border-radius: 5px;
    }
  }
  .photo-show {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 10px 0px;
  }
  .photo-show-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .camera-index-text {
    text-align: left;
    margin: 30px 0px 20px 0px;
    padding: 0px 50px;
    font-size: 15px;
    /*font-weight: 600;*/
    /*letter-spacing: 1px;*/
  }
  .camera-index-text-b {
    color: #F5A43C;
  }
  .circle {
    position: relative;
    width: 200px;
    height: 200px;
    // border-radius: 50%;
    margin: 0 auto 50px auto;

    .van-circle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .item-canvas {
      height: 200px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .van-loading-new{
                top:-40px;
                left:40%;
            }

    ::v-deep .van-uploader {
      width: 200px;
      height: 200px;
      // border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // background:#8b2d87;
      border: #8b2d87 1px dashed;
      .van-uploader__wrapper {
        width: 100%;
        height: 100%;
        // border-radius: 50%;

        .van-uploader__upload {
          width: 100%;
          height: 100%;
          margin: 0;
          // border-radius: 50%;
          background-color: transparent;

          .van-icon-photograph::before {
            content: "";
          }
        }

        .van-uploader__preview {
          margin: 0;
          width: 100%;
          height: 100%;
          // border-radius: 50%;

          .van-uploader__preview-image {
            width: 100%;
            height: 100%;
            // border-radius: 50%;

            // .van-image__img {
            //     object-fit: contain !important;
            // }
          }

          .van-uploader__preview-delete {
            // display: none;
          }
        }
      }
    }

    .pc-img {
      width: 160px;
      height: 160px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      overflow: hidden;

      canvas {
        height: 160px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .pc-video {
      width: 160px;
      height: 160px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      overflow: hidden;

      video {
        height: 160px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .result_box {
    text-align: center;
    margin-top: 30px;

    .my-icon {
      color: #ffffff;
      font-size: 40px;
      background: linear-gradient(to right, #563279, #8a2d87);
      border-radius: 50%;
      padding: 10px;
      margin-bottom: 10px;
    }
  }

  .camera-index-btn {
    text-align: center;
    margin: 20px auto;

    .van-button {
      color: #563279;
      border-color: #563279;
      height: 38px;
    }
  }
}
</style>
