import { request,allRequest } from "./network";

// 上传文件
export function fileUpload(data,file,name,onUploadProgress) {
  let formData = new FormData();
  let fileName = data['dir']+'/' + name;
  let url = data['host'];
  formData.append('OSSAccessKeyId', data['accessid']);
  formData.append('policy', data['policy']);
  formData.append('signature', data['signature']);
  formData.append('Filename', fileName);
  formData.append('key', fileName);
  formData.append('callback', data['callback']);
  formData.append('success_action_status', 200);
  formData.append("file", file);

  return request({
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
    onUploadProgress: onUploadProgress,
    method: "post",
    url: url,
  });
}

