import { request } from "./network";

//获取oss
export function getSignature() {
    return request({
        method: "get",
        url: "signatureOss/getSignature"
    });
}


// 保存学生图片
export function saveStudentImage(imageName,type) {
    let subjectUuid = localStorage.getItem('monitor-subjectUuId');
    return request({
        method: "post",
        url: "alivc/saveStudentImage/"+subjectUuid+'/'+type+'/'+imageName
    });
}

// 提交答案
export function submitAnswer(data) {
    return request({
        method: "post",
        url: 'student/commitAnswer',
        data: data
    });
}

// 提交答案-美院
export function submitAnswers(data) {
    return request({
        method: "post",
        url: 'student/commitAnswers',
        data: data
    });
}

// 获取提交的答案
export function getAnswer(data) {
    return request({
        method: "get",
        url: 'student/getStudentUrl',
        params: data
    });
}


// 获取当前监控里边科目以及数量
export function getSubjectCategories(subjectUuid, zkzNum) {
    return request({
        method: "get",
        url: `student/subjectCategories/${subjectUuid}/${zkzNum}`,
    });
}

