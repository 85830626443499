//人脸识别相关
import {Dialog, Toast,Notify } from 'vant';
import {getSignature} from "r/index/index";
import {fileUpload} from "r/index/upload";
import {faceMatch} from "r/index/camera";
import photoShow from "a/images/index/avater.jpg";
import uploadImg from "a/images/index/file_photo.png";
import {studentStatus} from "r/index/studentStatus";

export const cameraMixin = {
    data() {
        return {
            fileList: [],
            currentRate: 0,// 当前进度
            targetRate: 0,// 目标进度
            speed: 100,// 目标进度
            gradientColor: {
                '0%': '#8A2D87',
                '100%': '#563279',
            },
            checkStatus: 0,// 验证状态 0-未验证  1-验证成功  2-验证失败
            failCount: 0,// 失败次数
            photoShow: photoShow,
            uploadImg:uploadImg,
            isMobile: true,
            isChecked: false,// 防重复点击
            loadingFile:false
        };
    },
    created() {
        // let renzheng = localStorage.getItem("monitor-renzheng");
    },

    mounted() {
        localStorage.setItem("studentStatusTime",0);
        let exam_uuid = localStorage.getItem("monitor-subjectUuId");
        let sfzNum = localStorage.getItem("monitor-zkz");
        this.studentStatusPost(sfzNum,exam_uuid);

        if (this._isMobile()) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
            // 开启摄像头
            // this.callCamera();
        }
        // this.process(200, 200, 20, "#fafafa", 'procanvas1')
        const failCount = localStorage.getItem('monitor-failCount');
        if (failCount) {
            this.failCount = failCount;
        }
        if(this.failCount >= 3){
            Notify({ message: '跳过人脸识别，进入考试' ,color: '#eee',
            background: '#8b2d87'});
            this.$router.replace('/envir');//openBack 提示打开摄像头修改为直接调到上传环境照/envir add 20220508 baiansen
        }

    },
    methods: {

        // 判断是手机端，还是pc端
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },

         studentStatusPost(sfzNum,exam_uuid){
            var time1 = Date.parse(new Date());
            let studentStatusTime = localStorage.getItem("studentStatusTime");
            if( time1 > parseInt(studentStatusTime) + 10 || studentStatusTime == 0){
                studentStatus(sfzNum,exam_uuid).then((res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    localStorage.setItem("studentStatusTime", time1);
                    if(res.data.renzheng == 1){
                        this.$router.replace('/position');
                    }
                    let isVerify = localStorage.getItem("monitor-isVerify");
                    if(!isVerify){
                        this.$router.replace('/envir');//openBack 提示打开摄像头修改为直接调到上传环境照/envir add 20220508 baiansen
                    }
                });
            }
        },

        // pc-base64转Blob
        convertBase64ToBlob(base64) {
            let base64Arr = base64.split(',');
            let imgtype = '';
            let base64String = '';
            if (base64Arr.length > 1) {
                // 如果是图片base64，去掉头信息
                base64String = base64Arr[1];
                imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'));
            }
            // 将base64解码，atob() 方法用于解码使用 base-64 编码的字符串。
            let bytes = atob(base64String);
            let bytesCode = new ArrayBuffer(bytes.length);
            // 转换为类型化数组
            let byteArray = new Uint8Array(bytesCode);
            // 将base64转换为ascii码
            for (let i = 0; i < bytes.length; i++) {
                byteArray[i] = bytes.charCodeAt(i);
            }
            // 生成Blob对象（文件对象）
            return new Blob([bytesCode], {type: imgtype});
        },
        // pc-调用摄像头
        callCamera() {
            navigator.mediaDevices.getUserMedia({
                video: true
            }).then(success => {
                this.$refs['video'].srcObject = success;
                this.$refs['video'].play();
            }).catch(error => {
                console.log('摄像头开启失败，请检查摄像头是否可用');
            })
        },
        // pc-立即认证-拍照
        async photoGraph() {
            if(this.isChecked){
                return;
            }
            this.isChecked = true;
            let ctx = this.$refs['canvas'].getContext('2d');
            let cWidth = this.$refs['canvas'].offsetWidth;
            let cHeight = this.$refs['canvas'].offsetHeight;
            let vWidth = this.$refs['video'].offsetWidth;
            let vHeight = this.$refs['video'].offsetHeight;
            let w = (cWidth-vWidth)/2;
            let h = (cHeight-vHeight)/2;
            ctx.drawImage(this.$refs['video'], w, h, vWidth, vHeight);
            let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg', 0.7);
            let picFile = new File([this.convertBase64ToBlob(imgBase64)], 'face_img.png');
            let fileObj = {
                content: imgBase64,
                file: picFile
            }
            this.closeCamera();
            await this.afterRead(fileObj);
        },
        // pc-关闭摄像头
        closeCamera() {
            console.log(this.$refs['video'].srcObject)
            if (!this.$refs['video'].srcObject) return;
            let stream = this.$refs['video'].srcObject;
            let tracks = stream.getTracks();
            tracks.forEach(track => {
                track.stop();
            })
            this.$refs['video'].srcObject = null;
        },

        // 退出登录
        async logout() {
            Dialog.confirm({
                title: '提醒',
                message: '是否退出登录？',
            }).then(() => {
                Toast('退出成功');
                setTimeout(() => {
                    this.$store.commit("loginOut");
                    this.$router.replace('/login');
                }, 500)
            }).catch(() => {
                // on cancel
            });

        },


        // 手机-立即认证
        chooseFile() {

            let btn = this.$refs.uploader;
            btn.chooseFile();
        },
        // // 画图-圈
        // process(width, height, bdWidth, color, canvasId) {
        //     console.log(canvasId)
        //     let canvas = document.getElementById(canvasId);
        //     let context = canvas.getContext("2d");
        //     let center = [width / 2, height / 2];
        //     let borderWidth = bdWidth;
        //     let r = width / 2 - borderWidth / 2;
        //     canvas.width = width;
        //     canvas.height = height;
        //     //开始画一个灰色的圆
        //     let percent = 0.8;
        //     for (let i = 0; i < 125; i++) {
        //         context.save();
        //         context.beginPath();
        //         context.lineWidth = borderWidth;
        //         context.strokeStyle = color;
        //         //此处加的0.5是每格之间的间隔
        //         context.arc(center[0], center[1], r, ((2 * (i * percent + 0.5)) / 100) * Math.PI, ((2 * (i + 1) * percent) / 100) * Math.PI, false);
        //         context.stroke();
        //         context.restore();
        //     }
        // },
        // 人脸识别验证
        async onFaceMatch() {
            const zkz = localStorage.getItem('monitor-zkz');
            const subjectUuid = localStorage.getItem("monitor-subjectUuId");
            const fileNameFace =  zkz + '_' + subjectUuid + '.jpg';
            this.isChecked = false;

            studentStatus(zkz,subjectUuid).then((res) => {
                localStorage.setItem("monitor-huanjing", res.data.huanjing);
                localStorage.setItem("monitor-renzheng", res.data.renzheng);
                localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                if(res.data.renzheng == 1){
                    this.$router.replace('/envir');//openBack 提示打开摄像头修改为直接调到上传环境照/envir add 20220508 baiansen
               }else{
                   faceMatch(zkz,subjectUuid,fileNameFace).then( (data) => {
                        if (data.data.result === 'true') {
                            this.targetRate = 100;
                            this.checkStatus = 1;
                            Notify({ message: '识别已通过,请稍后！~~' ,color: '#eee',
                            background: '#8b2d87'});
                            setTimeout(() => {
                                this.$router.replace('/envir');//openBack 提示打开摄像头修改为直接调到上传环境照/envir add 20220508 baiansen
                            }, 3000)
                        } else {

                            this.speed = 10;
                            this.targetRate = 0;
                            this.speed = 100;
                            this.checkStatus = 2;


                            if(this.isMobile){
                                this.fileList = [];
                            }else{
                                let ctx=document.getElementById("canvas").getContext("2d");
                                ctx.clearRect(0,0,160,160);
                                this.callCamera();
                            }

                            this.failCount++;

                            localStorage.setItem('monitor-failCount', this.failCount);
                            if (this.failCount >= 3) {
                                Notify({ message: '身份认证未通过,将由监考老师进行身份检查,请先进行后续步骤' ,color: '#eee',
                                background: '#8b2d87'});
                                this.$router.replace('/envir');//openBack 提示打开摄像头修改为直接调到上传环境照/envir add 20220508 baiansen
                            }else{
                                Notify({ message: '身份认证失败,请确保按照要求拍摄人脸照片,重新认证' ,color: '#eee',
                                background: '#8b2d87'});
                            }
                        }
                   });

               }
            });

        },

        async afterRead(file) {
            file.status = 'uploading';
            file.message = '上传中...';
            const zkz = localStorage.getItem('monitor-zkz');
            const subjectUuid = localStorage.getItem("monitor-subjectUuId");
            // this.studentStatus(zkz,subjectUuid);

            studentStatus(zkz,subjectUuid).then((res) => {
                localStorage.setItem("monitor-huanjing", res.data.huanjing);
                localStorage.setItem("monitor-renzheng", res.data.renzheng);
                localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                if(res.data.renzheng == 1){
                    this.$router.replace('/envir');//openBack 提示打开摄像头修改为直接调到上传环境照/envir add 20220508 baiansen
                }else{
                    Notify({ message: '上传中' ,color: '#eee',
                    background: '#8b2d87'});
                    const fileName = 'face/' + zkz + '_' + subjectUuid + '.jpg';
                    this.fileList = [file];
                    getSignature().then( (data) => {
                        let onUploadProgress = (progressEvent => {
                            let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                            if (complete > 90) {
                                complete = 90;
                            }
                            this.targetRate = complete;
                        });
                        fileUpload(data.data, file.file, fileName, onUploadProgress).then( (uploadData) => {

                            if (uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK') {
                                file.status = 'done';
                                file.message = '上传成功...';
                                Notify({ message: '上传成功' ,color: '#eee',
                                    background: '#8b2d87'});
                                file.fileName = fileName;
                                // this.uploadImg = uploadData.data.url;
                                this.isChecked = true;
                                this.loadingFile = false;
                                // await this.onFaceMatch()
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                                this.fileList = [file];
                                Notify({ message: '上传失败' ,color: '#eee',
                                    background: '#8b2d87'});
                                this.isChecked = false;
                                this.loadingFile = false;
                            }
                        });

                    });

                }
            });

        },
        // 手机-立即认证
        beforeRead(file) {
            this.checkStatus = 0;
            // this.isChecked = true;
            return true;
        },

        deleteImg(file){
            this.fileList = [];
            this.isChecked = false;
            return true;
        }
    },
    destroyed() {
    },
};
